import React from "react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/layout"
import SEO from '../components/seo'
import YoutubeVideo from '../components/youtube-video'

const VideoTemplate = ({ pageContext, data }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <SEO
          title={data.video.title}
          description={data.video.body.value}
      />
      <div className="container content-page content-page-video">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={data.video.title}
          />

          <h1 className="title is-1">{data.video.title}</h1>

          <div className="date">
            <span>{data.video.created}</span>
          </div>
        </section>

        <section className="section page-content">
          <div className="video">
            <div className="video-wrapper">
              <YoutubeVideo url={data.video.field_video.uri}/>
            </div>
          </div>

          {data.video.body && (
            <div className="content" dangerouslySetInnerHTML={{__html: data.video.body.value }} />
          )}
        </section>
      </div>
    </Layout>
  )
}

export default VideoTemplate

export const query = graphql`
  query($internalId: Int!) {
    video: nodeVideo(status: {eq: true}, drupal_internal__nid: { eq: $internalId }) {
      title
      body {
        value
      }
      created(formatString: "DD MMMM YYYY", locale: "fr")
      field_video {
        uri
      }
    }
  }
`
