import React, {useEffect, useState} from "react"
import PropTypes from "prop-types";

const YoutubeVideo = ({ url, thumbnail }) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/

  const [videoThumbnail, setVideoThumbnail] = useState({
    url: null,
    width: 0,
    height: 0
  })

  let match = url.match(regExp)
  let videoId = (match && match[7].length === 11) ? match[7] : false

  useEffect(() => {
    if (videoId && thumbnail) {
      fetch(`https://www.googleapis.com/youtube/v3/videos?key=${process.env.YOUTUBE_CONTENT_API_KEY}&part=snippet&id=${videoId}`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          let thumbnails = json.items[0].snippet.thumbnails;
          for (let thumbnail in thumbnails) {
            if (videoThumbnail.width < thumbnails[thumbnail].width) {
              setVideoThumbnail(thumbnails[thumbnail])
            }
          }
        })
        .catch((error) => {
          console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
        })
      ;
    }
  }, [])

  return (
    <figure className={`image is-16by9${thumbnail ? ' has-play-button' : ''}`}>
      {thumbnail ? (
          <img
              src={videoThumbnail.url}
              alt="Youtube video"
          />
      ) : (
        <iframe
          className="has-ratio"
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          title="Youtube video"
          allowFullScreen
        >
        </iframe>
      )}
    </figure>
  )
}

YoutubeVideo.propTypes = {
  url: PropTypes.string.isRequired,
  thumbnail: PropTypes.bool
}

YoutubeVideo.defaultProps = {
  thumbnail: false
}

export default YoutubeVideo
